import { parseJwt } from '../commons/helpers/JWTHelpers';

const TOKEN_PARAM_NAME = 'yp-wr-token';
const TOKEN_MINUTES_EXPIRATION = 5;

function getTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(TOKEN_PARAM_NAME);
}

function removeTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(TOKEN_PARAM_NAME);
    window.history.replaceState({}, document.title, `${window.location.pathname}${urlParams.size > 0 ? `?${urlParams}` : ''}`);
}

/**
 * Try to parse the token and get the expiration date from it.
 * If the token is not valid, return a date in 5 minutes.
 *
 * @param {string} wrToken
 * @returns Expiration date object
 */
function getExpiration(wrToken) {
    let jwtPayload = null;
    try {
        jwtPayload = parseJwt(wrToken);
        return new Date(jwtPayload.exp * 1000);
    } catch (e) {
        console.error(e);
    }
    const d = new Date();
    return d.setTime(d.getTime() + (1000 * 60 * TOKEN_MINUTES_EXPIRATION));
}

function setCookie(wrToken) {
    const expirationDate = getExpiration(wrToken);
    // Strip the leading dot from the domain if it exists (it should)
    // Because this cookie should not be accessible from subdomains
    const domain = YP_CONFIG.cookieDomain.replace(/^\./, '');
    const cookie = `${TOKEN_PARAM_NAME}=${wrToken};expires=${expirationDate.toUTCString()};path=/;domain=${domain};secure;SameSite=Strict`;
    document.cookie = cookie;
}

(() => {
    const token = getTokenFromUrl();
    if (!token) {
        return;
    }
    setCookie(token);
    removeTokenFromUrl();
})();
